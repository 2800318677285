import React from 'react';

const Resume = () => {
	return (
		<div id="resume">
			<div className="container">
				<header className="row">
					<div className="col-md">
						<div className="logo">
							<div className="tag">
								<span>
									CbNS<span className="trade">TM</span>
								</span>
							</div>
							<h1>
								Nicholas Sherman
								<span>
									developer <em>+</em> analyst
								</span>
							</h1>
						</div>
					</div>
					<aside className="col-md flex-items-md-right info">
						<ul>
							<li>
								<a href="mailto:codebyns@gmail.com">
									<i className="fas fa-envelope mail" />codebyns@gmail.com
								</a>
							</li>
							<li>
								<a href="https://github.com/neekyo">
									<i className="devicon-github-plain gitLogo" />
									neekyo
								</a>
							</li>
						</ul>
					</aside>
				</header>
				<div className="row">
					<div className="col-md">
						<article>
							<header>
								<i className="fas fa-bullseye" />
								<h2>Objective</h2>
							</header>
							<p>
								Seeking a full-time developer or engineer positon that will allow me to continue to grow
								my skills alongside my respective company.
							</p>
							<p>
								I have 5 years of professional experience working as an <span>analyst</span>, and 3
								years experience as a <span>full-stack developer</span>. Learning how to code and create
								innovative solutions fascinates me, and in combination with my analysis experience will
								propel me in the tech industry.
							</p>
						</article>
						<article>
							<header>
								<i className="fas fa-database" />
								<h2>Experience</h2>	
							</header>
							<ul className="time-line">
								<li>
									<div>
										<span className="time-date">2021-2023</span>
										<span className="time-date-subtitle">CbNS</span>
										<span className="time-point" />
										<div className="content">
											<h3>Senior Software Engineer</h3>
												<p>
												Completing various projects working for myself as a freelancer while expanding my skillset &amp; experience as a full-stack web developer &amp; software engineer.
											</p>
										</div>
									</div>
								</li>
								<li>
									<div>
										<span className="time-date">2020-2021</span>
										<span className="time-date-subtitle">Tint World</span>
										<span className="time-point" />
										<div className="content">
											<h3>Lead Frontend Developer</h3>
											<p>
												Worked hand-in-hand with DoT to launch revamped website and streamline effienciency with dev ops using PHP &amp; pipelining. 
											</p>
											<p>
												Redesigned &amp; templated site as well as working interdepartmentally with marketing team to design emails via MJML &amp; cloudinary.
											</p>
										</div>
									</div>
								</li>
								<li>
									<div>
										<span className="time-date">2019-2020</span>
										<span className="time-date-subtitle">Affinity Networks, Inc.</span>
										<span className="time-point" />
										<div className="content">
											<h3>Lead Software Development Engineer</h3>
											<p>
												Implemented Alexa skill building and integration via Node backend, AWS
												Lambda Deployment and Git Pipeline.
											</p>
											<p>
												Aesthetically re-designed and updated several company{' '}
												<a className="orange" href="http://www.theanigroup.com">
													websites
												</a>{' '}
												via WordPress.
											</p>
										</div>
									</div>
								</li>
								<li>
									<div>
										<span className="time-date">2018-2019</span>
										<span className="time-date-subtitle">Invicta Mortgage Group, Inc.</span>
										<span className="time-point" />
										<div className="content">
											<h3>Senior Mortgage Consultant</h3>
											<p>
												Coordinated with prospective borrowers to originate the best loan
												portfolio for their financial situation.
											</p>
										</div>
									</div>
								</li>	
								<li>
									<div>
										<span className="time-date">2017-2018</span>
										<span className="time-date-subtitle">Ocwen Financial Corporation</span>
										<span className="time-point" />
										<div className="content">
											<h3>Analyst, Investor Accounting</h3>
											<p>
												Ensured ad hoc reconciliations were accurate and completed within
												investor requirements and correct format, and reconciling items were
												properly identified, researched, and cleared within 90 days.
											</p>
											<p>
												Multi-tasked and troubleshot any issues that arose which may have had
												multiple variables that fell outside stipulated guidelines and worked
												closely with multiple departments to ensure their prompt and accurate
												resolution.
											</p>
										</div>
									</div>
								</li>
								<li>
									<div>
										<span className="time-date">2015-2017</span>
										<span className="time-date-subtitle">Bankrate, Inc.</span>
										<span className="time-point" />
										<div className="content">
											<h3>Data & Market Analyst</h3>
											<p>
												Analyzed financial rates and data points to provide consumers with sound
												insight on financial services including mortgages, loans, checking, and
												deposits.
											</p>
											<p>
												Researched market rates and pricing trends in order to establish
												benchmark national averages for consumer comparisons.
											</p>
											<p>
												Surveyed dozens of markets across the U.S. to monitor and analyze market
												conditions and dynamics, including top 25 markets Miami and Tampa.
											</p>
										</div>
									</div>
								</li>
								<li>
									<div>
										<span className="time-date">2015-2015</span>
										<span className="time-date-subtitle">Carmine's Restaurant Group</span>
										<span className="time-point" />
										<div className="content">
											<h3>Marketing Consultant</h3>
											<p>
												Helped position their website properly with SEO optimization and
												financial analysis.
											</p>
											<p>
												Assisted in formulating a concrete marketing plan providing how to
												optimize social media, website, and email marketing to increase sales
												efficiency.
											</p>
										</div>
									</div>
								</li>
							</ul>
						</article>
					</div>
					<div className="col-md">
						<article>
							<header>
								<i className="fas fa-apple-alt" />
								<h2>Education</h2>
							</header>
							<ul className="time-line">
								<li>
									<div>
										<span className="time-date">2019-2019</span>
										<span className="time-date-subtitle">Ironhack</span>
										<span className="time-point" />
										<div className="content">
											<h3>Web Development</h3>
											<p>
												Full-time web development graduate amassing over 400+ hours of
												experience learning to code in numerous languages and frameworks. Upon
												graduation, Ironhack bootcamp was ranked the #2 bootcamp in the world.
											</p>
										</div>
									</div>
								</li>
								<li>
									<div>
										<span className="time-date">2009-2014</span>
										<span className="time-date-subtitle">University of Florida</span>
										<span className="time-point" />
										<div className="content">
											<h3>Warrington College of Business</h3>
											<p>
												Received a BS degree in Business and an AA Degree in Finance, as well as
												a minor in Business Administration.
											</p>
										</div>
									</div>
								</li>
								<li>
									<div>
										<span className="time-date">2004-2009</span>
										<span className="time-date-subtitle">Suncoast Highschool</span>
										<span className="time-point" />
										<div className="content">
											<h3>International Baccalaureate Program</h3>
											<p>
												Diploma program graduate receiving a 100% Bright Futures Scholarship, as
												well as a perfect score (36) on ACT Math. At the time of graduation,
												Suncoast Highschool was ranked among the top 3 high schools in the
												nation.
											</p>
										</div>
									</div>
								</li>
							</ul>
						</article>
						<article>
							<header>
								<i className="fas fa-magic" />
								<h2>Skills</h2>
							</header>
							<p>
								Below are technologies that I use to build awesome applications with (in no particular
								order).
							</p>
							<ul className="skills">
								<li className="skill-JavaScript">
									<span className="icon-label">JavaScript</span>
									<i className="devicon-javascript-plain" />
								</li>
								<li className="skill-PHP">
									<span className="icon-label">PHP</span>
									<i className="devicon-php-plain" />
								</li>
								<li className="skill-NodeJS">
									<span className="icon-label">Node.js</span>
									<i className="devicon-nodejs-plain" />
								</li>
								<li className="skill-React">
									<span className="icon-label">React</span>
									<i className="devicon-react-original" />
								</li>
								<li className="skill-Vue">
									<span className="icon-label">Vue</span>
									<i class="devicon-vuejs-plain"></i>
								</li>
								<li className="skill-HTML5">
									<span className="icon-label">HTML5</span>
									<i className="devicon-html5-plain" />
								</li>
								<li className="skill-CSS3">
									<span className="icon-label">CSS3</span>
									<i className="devicon-css3-plain" />
								</li>
								<li className="skill-Bootstrap">
									<span className="icon-label">Bootstrap</span>
									<i className="devicon-bootstrap-plain" />
								</li>
								<li className="skill-Photoshop">
									<span className="icon-label">Photoshop</span>
									<i class="devicon-photoshop-plain"></i>
								</li>
								<li className="skill-Git">
									<span className="icon-label">Git</span>
									<i className="devicon-git-plain" />
								</li>
								<li className="skill-Handlebars">
									<span className="icon-label">Handlebars</span>
									<i className="devicon-handlebars-plain" />
								</li>
								<li className="skill-Mongo">
									<span className="icon-label">MongoDB</span>
									<i className="devicon-mongodb-plain" />
								</li>
								<li className="skill-NGINX">
									<span className="icon-label">NGINX</span>
									<i className="devicon-nginx-original" />
								</li>
								<li className="skill-Heroku">
									<span className="icon-label">Heroku</span>
									<i className="devicon-heroku-original" />
								</li>
								<li className="skill-jQuery">
									<span className="icon-label">jQuery</span>
									<i className="devicon-jquery-plain" />
								</li>
								<li className="skill-Less">
									<span className="icon-label">Less</span>
									<i className="devicon-less-plain-wordmark" />
								</li>
								<li className="skill-TypeScript">
									<span className="icon-label">TypeScript</span>
									<i className="devicon-typescript-plain" />
								</li>
								<li className="skill-AWS">
									<span className="icon-label">AWS</span>
									<i className="devicon-amazonwebservices-original" />
								</li>
								<li className="skill-Docker">
									<span className="icon-label">Docker</span>
									<i className="devicon-docker-plain"></i>
								</li>
								<li className="skill-Babel">
									<span className="icon-label">Babel</span>
									<i className="devicon-babel-plain"></i>
								</li>
								<li className="skill-Wordpress">
									<span className="icon-label">Wordpress</span>
									<i className="devicon-wordpress-plain"></i>
								</li>
							</ul>
						</article>
						<article>
							<header>
								<i className="fas fa-heart" />
								<h2>Hobbies</h2>
							</header>
							<ul className="hobbies">
								<li>
									<i className="fas fa-film" />
									<span>Movies</span>
								</li>
								<li>
									<i className="fas fa-music" />
									<span>Music</span>
								</li>
								<li>
									<i className="fas fa-flag-checkered" />
									<span>Cars</span>
								</li>
								<li>
									<i className="fas fa-beer" />
									<span>Brews</span>
								</li>

								<li>
									<i className="fas fa-gamepad" />
									<span>Gaming</span>
								</li>
							</ul>
						</article>
						<article>
							<header>
								<i className="fas fa-comments" />
								<h2>Language</h2>
							</header>
							<p>Fluent in English; Limited working proficiency in Spanish.</p>
						</article>
						<aside>
							<em>References available upon request.</em>
						</aside>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Resume;
