import React from 'react';

const Home = () => {
	return (
		<>
			<div id="home" className="no-scroll">
				<div className="greeting">
					<span className="border">ALWAYS BE</span>
					<br />
					<div className="border-2">
						<span className="border">CODING</span>
					</div>
				</div>
				<div id="contact">
					<div className="logos">
						<a href="https://github.com/neekyo">
							<i className="fab fa-github" />
						</a>
						<a href="https://www.linkedin.com/in/nicksherman15/">
							<i className="fab fa-linkedin" />
						</a>
					</div>
					<span className="name">Nicholas Sherman</span>
				</div>
				<div className="brand">
					<span>c</span>
					<span>o</span>
					<span>d</span>
					<span>e</span>
					<span>b</span>
					<span>y</span>
					<span>n</span>
					<span>s</span>
				</div>
			</div>
		</>	
	);
};

export default Home;
