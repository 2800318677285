import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './Home';
import Resume from './Resume';

const App = () => {
	return (
		<div className="App">
			<BrowserRouter>
				<Switch>
					<Route exact path="/" component={Home} />
					<Route exact path="/resume" component={Resume} />
				</Switch>
			</BrowserRouter>
		</div>
	);
};

export default App;
